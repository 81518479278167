<template>
    <div class="lct-detail-content">
        <div class="lct-detail-head">
            <div class="lct-detail-head-main">
                <div class="lct-detail-head-crumbs">
                    <span class="on">课程</span><i class="iconfont icon-jiantou"></i><router-link to="/lct">手术视频</router-link><i class="iconfont icon-jiantou"></i><span>{{ info.title }}</span>
                </div>
                <!-- <div class="lct-detail-head-video">
                    <video id="player-container-id" style="width:100%; height:100%;" preload="auto" playsinline webkit-playsinline></video>
                    <div class="no-course-error" v-if="noCourse">
                        <img :src="info.banner" v-if="noCourse"/>
                    </div>
                </div> -->
            </div>
			<div class="lct-plauy-video-main">
				<!-- 云点播视频区域 -->
				<div class="tcp-container">
				    <video id="player-container-id" preload="auto" playsinline webkit-playsinline></video>
				    <div class="no-course-error" v-if="noCourse">
				        <img :src="info.banner" v-if="noCourse"/>
				    </div>
				</div>
				<!-- 实时音视频区域 -->
				<div class="rtc-container">
					<!-- 本地流区域 -->
					<div class="local-stream-container">
						<!-- 设备选择区域 -->
						<div class="cameraSelect1">
							<span>摄像头选择</span>
							<el-select v-model="activeCameraId" placeholder="请选择" @change="handleCameraChange">
								<el-option
								v-for="item in cameraList"
								:key="item.deviceId"
								:label="item.label"
								:value="item.deviceId">
								</el-option>
							</el-select>
						</div>
						<div class="cameraSelect2">
							<span>分辨率选择</span>
							<el-select v-model="activeProfile" placeholder="请选择" @change="handleProfileChange">
								<el-option label="360p" value="360p"></el-option>
								<el-option label="540p" value="540p"></el-option>
								<el-option label="720p" value="720p"></el-option>
								<el-option label="1080p" value="1080p"></el-option>
							</el-select>
						</div>

						<!-- 本地流播放区域 -->
						<div id="local" class="local-stream-content"></div>
						<!-- 本地流操作栏 -->
						<div class="local-stream-control">
							<!-- 全屏 -->
							<i class="el-icon-full-screen" @click="fullscreen"></i>
						</div>
					</div>
				</div>
			</div>
        </div>
        <div class="play-content">
            <el-tabs v-model="activeName">
                <el-tab-pane label="选择章节" name="first">
                </el-tab-pane>
                <el-tab-pane label="学员留言" name="second">
                </el-tab-pane>
                <el-tab-pane label="课程介绍" name="info"></el-tab-pane>
            </el-tabs>
            <template v-if="activeName == 'first'">
                <div class="play-chapter-item" v-for="(item,index) in list" :key="index">
                    <div class="play-chapter-item-title">
                        {{ item.chapter_name }}
                    </div>
                    <div class="play-chapter-item-bar" v-for="(arr,i) in item.video_list" :key="i" :class="videoData.id == arr.id?'on':''" @click="videoChange(arr)">
                        <div class="play-chapter-item-bar-name"><img :src="videoData.id == arr.id? 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/play-on2@2x.png':'https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/play@2x.png'"/>{{ arr.title }}</div>
                        <div class="play-chapter-item-bar-no" v-if="videoData.id != arr.id"><span>{{ arr.lenght }}</span><strong>|</strong><span>{{ arr.pv }}次观看</span></div>
                        <div class="play-chapter-item-bar-start" v-if="videoData.id == arr.id"><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/play/zhibo.gif"/> 正在播放</div>
                    </div>
                </div>
            </template>
            <template v-if="activeName == 'second'">
                <div class="play-chat">
                    <textarea placeholder="我也来说..." v-model="sendTxt"></textarea>
                    <div class="play-chat-send">
                        <el-button type="primary" round :disabled="this.sendTxt == ''" @click="send()">发送</el-button>
                    </div>
                </div>
                <div class="play-chat-list">
                    <div class="play-chat-list-item" v-for="(item,index) in chatList">
                        <div class="play-chat-item-head"><img :src="item.face || require('../../assets/img/my.png')"/></div>
                        <div class="play-chat-item-other">
                            <div class="play-chat-item-title">{{ item.real_name }}</div>
                            <div class="play-chat-item-content">
                                {{ item.content }}
                            </div>
                            <div class="play-chat-item-time">{{ item.createtime }}</div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-count="total"
                            @current-change="currentChange">
                    </el-pagination>
                </div>
            </template>
            <template v-if="activeName == 'info'">
                <div v-html="details.details"></div>
            </template>

        </div>
    </div>
</template>

<script>
	import TRTC from 'trtc-sdk-v5';
    export default {
        data() {
            return {
                list: [], // 章节列表
                info: {}, // 详情信息
                details: {}, // 详情信息
                videoData: {}, // 当前播放信息
                tcplayer: null,
                activeName: 'first',
                total: 0,
                page: 1,

                sendTxt: '',
                chatList: [], // 聊天列表
                websock: null,
                noCourse: false,
				trtc:null,
				cameraList:[],
				activeCameraId:'',
				activeProfile:'1080p'
            }
        },
        mounted() {
			console.log('this.chatList',this.chatList)
            this.getPlay();
            this.getDetail();
			this.trtc = TRTC.create();
			navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
				TRTC.getCameraList().then(list=>{
					this.cameraList=list;
					this.activeCameraId=list[0].deviceId;
					this.trtc.startLocalVideo({
						view: 'local',
						option: {
							cameraId: list[0].deviceId,
							profile:this.activeProfile,
							mirror:false
						}
					})
				})
			})
			
			
			// navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
			// 	TRTC.getCameraList().then(list=>{
			// 		this.cameraList=list;
			// 		this.activeCameraId=list[0].deviceId;
			// 		this.trtc.startLocalVideo({
			// 			view: 'local',
			// 			option: {
			// 				cameraId: list[0].deviceId,
			// 				profile: '480p'
			// 			}
			// 		})
			// 	})
			// })
			navigator.mediaDevices.addEventListener('devicechange', this.updateCameraList)
        },
		beforeDestroy(){
			navigator.mediaDevices.removeEventListener('devicechange', this.updateCameraList)
		},
        destroyed() {
            this.tcplayer.dispose()
        },
        computed: {
            uid() {
                return this.$store.state.uid
            }
        },
        methods: {
			// 全屏
			fullscreen(){
				// 获取视频元素
				var video = document.getElementById("local");
				// 判断当前浏览器支持的全屏API
				if (video.webkitRequestFullScreen) { // Chrome、Safari等WebKit内核浏览器
				    video.webkitRequestFullScreen();
				} else if (video.mozRequestFullScreen) { // Firefox浏览器
				    video.mozRequestFullScreen();
				} else if (video.msRequestFullscreen) { // IE/Edge浏览器
				    video.msRequestFullscreen();
				} else if (video.requestFullscreen) { // W3C标准的全屏API
				    video.requestFullscreen();
				}
			},
			handleCameraChange(){
				this.trtc.updateLocalVideo({
					option: { cameraId: this.activeCameraId }
				})
			},
			handleProfileChange(){
				this.trtc.updateLocalVideo({
					option: { 
						// cameraId: this.activeCameraId, 
						profile:this.activeProfile
					}
				})
			},
			updateCameraList(){
				TRTC.getCameraList().then(list=>{
					this.cameraList=list;
					this.activeCameraId=list[0].deviceId;
					this.trtc.updateLocalVideo({
						option: {
							cameraId: list[0].deviceId,
						}
					})
				})
			},
            getDetail() {
                this.$axios.post(this.apiUrl.courseDetails, {
                    id: this.$route.params.id
                }).then(res => {
                    this.details = res.data;
                })
            },
            getPlay() {
                this.$axios.post(this.apiUrl.CourseCatalog, {
                    id: this.$route.params.id,
                    tab: 2
                }).then(res2 => {
                    if(res2.code == 1) {
                        this.$axios.post(this.apiUrl.course, {
                            id: this.$route.params.id
                        }).then(res => {
                            this.info = res.data;
                            this.list = res2.data.video;
                            if(res2.data.video.length>0 && res2.data.video[0].video_list.length>0) {
                                this.videoData = res2.data.video[0].video_list[0];
                                this.play(this.videoData.file_id);
                            }else {
                                this.noCourse = true;
                                this.$message.warning("未上传课程，请等待")
                            }
                            this.getChat();
                            this.initWebSocket()
                            this.study_add({
                                uid: this.uid,
                                vid: this.videoData.id,
                                study_time: this.videoData.study_time,
                                leng_time: 1,
                                duration: this.videoData.second,
                                status: 1,
                            })
                        })
                    } else {
                        this.$message.warning(res2.msg);
                        this.$router.replace({path: `/lct/${this.$route.params.id}/detail`})
                    }

                })
            },
            getChat() {
                this.$axios.post(this.apiUrl.chat2, {
                    cid: this.$route.params.id,
                    cstyle: 2,
                    page: this.page,
                    rank: 2
                }).then(res => {
					if(res.data){
						this.total = res.data.allpage;
						this.chatList = res.data.list;
					}
                })
            },
            currentChange(e) {
                this.page = e;
                this.getChat();
            },
            play(file_id) {
                this.$axios.post(this.apiUrl.getVideoUrl_One, {
                    fileid: file_id
                }).then(res => {
                    this.videoData.file_id = file_id;
                    this.videoData.psign = res.data.signpc;
                    this.tcplayer = TCPlayer('player-container-id', {
                        fileID: this.videoData.file_id,
                        appID: '1258924221',
                        psign: this.videoData.psign,
                        poster: this.info.banner,
                        autoplay: true,
                        //其他参数请在开发文档中查看
                        plugins: {
                            ProgressMarker: true,
                            ContinuePlay: {
                                auto: true,
                                text:'上次播放至 '
                            }
                        }
                    });
                })
            },
            videoChange(obj) {
                this.videoData = obj;
                console.log(this.videoData)
                this.$axios.post(this.apiUrl.getVideoUrl_One, {
                    fileid: this.videoData.file_id
                }).then(res => {
                    this.videoData.psign = res.data.signpc;
                    this.tcplayer.loadVideoByID({fileID: this.videoData.file_id, appID: '1258924221', psign: this.videoData.psign,autoplay: true});
                    this.$message.success('切换成功');
                    this.study_add({
                        uid: this.uid,
                        vid: this.videoData.id,
                        study_time: this.videoData.study_time,
                        leng_time: 1,
                        duration: this.videoData.second,
                        status: 1,
                    })
                })
            },

            send() {
                if(this.sendTxt == '' || !this.sendTxt) {
                    this.$message.warning("请输入内容");
                    return false
                }
                this.$axios.post(this.apiUrl.setChat, {
                    cid: this.$route.params.id,
                    content: this.sendTxt,
                    content_type: "text",
                    cstyle: 2
                }).then(res => {
                    if (res.code == 1) {
                        res.data.createtime = this.formatDate(res.data.createtime)
                        this.websocketsend(JSON.stringify({
                            type: 2,
                            nickname: res.data.real_name,
                            ...res.data
                        }));
                        this.sendTxt = ''
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            formatDate(date) {
                var date = new Date(date * 1000);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            },
            study_add(obj) {
                this.$axios.post(this.apiUrl.study_add, obj).then(res => {
                })
            },
            initWebSocket() { //初始化weosocket
                const wsuri = "wss://yalaworker.ysxxlm.com";
                this.websock = new WebSocket(wsuri);
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onopen = this.websocketonopen;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            websocketonopen() { //连接建立之后执行send方法发送数据
                let param = {
                    "type": 7,
                    "cid": this.$route.params.id,
                    "class": "Live"
                };
                this.websocketsend(JSON.stringify(param));
            },
            websocketonerror() { //连接建立失败重连
                this.initWebSocket();
            },
            websocketonmessage(e) { //数据接收
                const redata = JSON.parse(e.data);
                //这里分析回复的内容，执行不同的操作
                //写自己的业务逻辑
                var myDate = new Date();
                var tiem = myDate.getFullYear() + '-' + (myDate.getMonth() + 1) + '-' + myDate.getDate() + ' ' + myDate.getHours() +
                    ':' + (myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes() + ':' + myDate.getSeconds())
                if (redata && redata.message == "interactiveChatMsg") {
                    //直播间弹幕消息
                    var message_data = JSON.parse(redata.data);
                    this.chatList.unshift({
                        "face": message_data.face,
                        "real_name": message_data.nickname,
                        "content": message_data.content,
                        uid: message_data.uid,
                        create_time: tiem,
                        createtime: tiem,
                        comment_type: 1,
                    });
                }
                if (redata && redata.message == "send_gift") {
                    //直播礼物消息
                }
                /* 抽奖按钮显示 */
                if (redata && redata.message == "startSeckill") {
                }
                /* 中奖人数显示 */
                if (redata && redata.message == "seckill_success") {
                }
                if (redata && redata.message == "startLive") {
                }
                if (redata && redata.message == "endLive") {
                }
            },
            websocketsend(Data) { //数据发送
                this.websock.send(Data);
            },
            websocketclose(e) { //关闭
                console.log('断开连接', e);
            }
        }
    }
</script>

<style lang="scss" scoped>
	.lct-plauy-video-main{
		width: 100%;
		display: flex;
		height: 725px;
		.tcp-container{
			flex:0.5;
			position: relative;
			#player-container-id{
				width: 100%;
				height: 100%;
			}
			.no-course-error{
			  position: absolute;
			  left: 0;
			  top: 0;
			  right: 0;
			  height: 100%;
			  z-index: 9;
			  img{
			    width: 100%;
			    height: 100%;
			  }
			}
		}
		.rtc-container{
			height: 100%;
			flex:0.5;
			background: #000;

			.local-stream-container{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				// .local-stream-content{
				// 	width: 100%;
				// }
			}
			.local-stream-container, .local-stream-content{
				width: 100%;
				height: 100%;
			}
			.local-stream-container{
				position: relative;
				.cameraSelect1, .cameraSelect2{
					position: absolute;
					left: 0;
					top: -45px;
					span{
						display: inline-block;
						margin-right: 10px;
					}
				}
				
				.cameraSelect2{
					left: 300px;
				}
				.local-stream-control{
					position: absolute;
					bottom: 0;
					right: 5px;
					color: #fff;
					font-size: 16px;
				}

			}
		}
	}
</style>
